<template>
  <div :class="ui.wrapper" v-bind="attrs">
    <span v-if="$slots.default" :class="ui.text"><slot /></span>
    <img
      v-if="disabled"
      :class="ui.icon"
      src="@/assets/token-disabled.svg?sprite"
      alt="Disabled Token Icon"
    />
    <img
      v-else
      :class="ui.icon"
      src="@/assets/token.svg?sprite"
      alt="Token Icon"
    />
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  size: {
    type: String as PropType<'xs' | 'sm' | 'md' | 'lg' | 'full'>,
    default: 'sm',
    validator: (value: string): boolean =>
      ['xs', 'sm', 'md', 'lg', 'full'].includes(value),
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  class: {
    type: [String, Object, Array] as PropType<any>,
    default: undefined,
  },
  ui: {
    type: Object as PropType<Partial<typeof config.value>>,
    default: () => ({}),
  },
});

const slots = useSlots();
const hasDefaultSlot = computed(() => !!slots.default);

const iconSize: ComputedRef<string> = computed(() => {
  switch (props.size) {
    case 'xs':
      return 'h-4 w-4';
    case 'sm':
      return 'h-6 w-6';
    case 'md':
      return 'h-8 w-8';
    case 'lg':
      return 'h-12 w-12';
    default:
      return 'w-full h-auto';
  }
});

const config = computed(() => ({
  wrapper: 'flex items-center',
  text: hasDefaultSlot.value ? 'mr-1' : 'mr-0',
  icon: `inline-block ${iconSize.value}`,
}));

const { ui, attrs } = useUI(
  'TnTokenIcon',
  toRef(props, 'ui'),
  config,
  toRef(props, 'class'),
  true
);
</script>
